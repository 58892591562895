import React, { useEffect, useState, useCallback } from 'react';

const Cookies = () => {
  const [isShowCookie, setIsShowCookie] = useState(false);

  const handleShowCookies = useCallback(() => {
    localStorage.setItem('cookie', true);
    setIsShowCookie(false);
  }, []);

  useEffect(() => {
    const cookie = localStorage.getItem('cookie');

    setIsShowCookie(!cookie);
  }, []);

  if (!isShowCookie) return null;

  return (
    <div className='fixed z-[199] bottom-0 right-0 left-0 sm:bottom-2 sm:right-auto sm:max-w-7xl sm:w-full sm:left-1/2 sm:-translate-x-1/2 p-2'>
      <div className="p-4 sm:p-6 rounded-2xl  bg-white shadow-[0_8px_24px_rgba(0,0,0,0.16)]">
        <div className="sm:flex sm:justify-between">
          <div className="max-w-3xl">
            <div className="mb-2 text-2xl font-bold text-black">This website uses cookies</div>
            <div className="text-[13px] text-black/50 font-medium leading-tight	">SafeSoul processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests.By clicking "Accept cookies" or by continuing to browse the site, you agree to the use of these cookies.</div>
          </div>

          <div className="mt-5 sm:mt-0 sm:ml-10 flex-shrink-0">
            <button className="flex items-center justify-center w-full sm:w-auto rounded-lg px-10 py-2 bg-black text-white font-bold leading-none hover:bg-black/90 transition-all h-14" onClick={handleShowCookies}>Accept cookies & LFG</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;