import React from 'react';

import { WagmiConfig } from "wagmi";
import {
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

import { chains, wagmiClient } from "../../helpers/configureRainbow";

const WagmiWrapper = ({ children }) => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} modalSize="compact">
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default WagmiWrapper;