exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-achievement-[id]-js": () => import("./../../../src/pages/achievement/[id].js" /* webpackChunkName: "component---src-pages-achievement-[id]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-preview-academy-[id]-js": () => import("./../../../src/pages/preview/academy/[id].js" /* webpackChunkName: "component---src-pages-preview-academy-[id]-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-wallet-connect-js": () => import("./../../../src/pages/wallet-connect.js" /* webpackChunkName: "component---src-pages-wallet-connect-js" */),
  "component---src-sections-academy-index-jsx": () => import("./../../../src/sections/academy/index.jsx" /* webpackChunkName: "component---src-sections-academy-index-jsx" */),
  "component---src-sections-post-index-jsx": () => import("./../../../src/sections/post/index.jsx" /* webpackChunkName: "component---src-sections-post-index-jsx" */)
}

