const React = require("react");
const { default: Cookies } = require("./src/components/common/Cookies");
const WagmiWrapper = require('./src/components/common/WagmiWrapper').default;
require('./src/styles.scss');
require('@rainbow-me/rainbowkit/styles.css');

exports.wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Cookies />
      <WagmiWrapper>{element}</WagmiWrapper>
    </>
  );
}