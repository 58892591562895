import {
  getDefaultWallets,
  connectorsForWallets
} from '@rainbow-me/rainbowkit';
import {
  argentWallet,
  imTokenWallet,
  coinbaseWallet,
  metaMaskWallet,
  walletConnectWallet,
  injectedWallet,
  ledgerWallet,
  rainbowWallet,
  trustWallet,
  omniWallet,
  braveWallet
} from '@rainbow-me/rainbowkit/wallets';

import { mainnet, goerli, configureChains, createClient } from 'wagmi';
import { polygon } from '@wagmi/core/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';

export const { chains, provider } = configureChains(
  [goerli, mainnet, polygon],
  [
    infuraProvider({ apiKey: process.env.INFURA_TOKEN }),
    publicProvider()
  ]
);

export const connectors = connectorsForWallets([
  {
    groupName: 'Popular',
    wallets: [
      metaMaskWallet({ chains }),
      coinbaseWallet({ chains }),
      trustWallet({ chains }),
      // ledgerWallet({ chains }),
    ],
  },
  // {
  //   groupName: 'Other',
  //   wallets: [
  //     braveWallet({ chains }),
  //     walletConnectWallet({ chains }),
  //     argentWallet({ chains }),
  //     imTokenWallet({ chains }),
  //     injectedWallet({ chains }),
  //     omniWallet({ chains }),
  //     braveWallet({ chains }),
  //     rainbowWallet({ chains }),
  //   ],
  // },
]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});